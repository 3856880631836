import { addDoc, collection, getDocs, query, Timestamp, where } from 'firebase/firestore';
import { db } from '../../firebase';

export async function apiSaveTrainingSession(uid, tid, tname, time, messages) {
    const data = {
        uid,
        tid,
        tname,
        messages
    }
    data.time = Timestamp.fromMillis(time)
    return await addDoc(collection(db, 'saved_sessions'), data);
}

export async function fetchUserSessions(uid) {
    const arr = [];
    const q = query(collection(db, 'saved_sessions'), where('uid', '==', uid))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
        const data = doc.data()
        data.doc_id = doc.id
        if (data.time && data.time.seconds) {
            data.time = data.time.seconds * 1000
        }
        else {
            data.time = undefined
        }
        arr.push({id: doc.id, data})
    });
    return arr;
}