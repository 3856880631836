import React from 'react';
import { Chat } from '../features/chat/Chat';

const ChatPage = () => {
    return(
        <>
            <Chat></Chat>
        </>
    )
}

export default ChatPage;