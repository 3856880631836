export const AppStatus = {
    Idle: 'idle',
    Loading: 'loading'
}

export const AuthStatus = {
    Unknown: 'unknown',
    Authorized: 'authorized',
    Loggedout: 'loggedout'
}

export const AIWhitelistRoles = {
    assistant: 'assistant',
    system: 'system',
    user: 'user',
}