import React, { useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { selectLogo } from '../features/user/userSlice';
import SessionsList from '../features/saved_sessions/SessionsList';
import { useSelector } from 'react-redux';
import { Menu } from '../features/menu/Menu';

const SessionsPage = () => {

    const logo = useSelector(selectLogo);
    const [menuOpen, setMenuOpen] = useState(false);

    const showMenu = () => {
        setMenuOpen(true);
    };

    const onCloseMenu = () => {
        setMenuOpen(false);
    };

    return(
        <>
            <nav>
                <div className='top_actions'>
                    <Button className='btn menu_btn' onClick={showMenu}>
                        <MenuOutlined />
                    </Button>
        		</div>
                <div className='top'>
                    {logo &&
                        <div className='logo' style={{backgroundImage: `url(${logo})`}}></div>
                    }
                    <h2>
                        My progress
                    </h2>
                </div>
                <SessionsList></SessionsList>
            </nav>
            <Menu menuOpen={menuOpen} onCloseMenu={onCloseMenu}></Menu>
        </>
    )
}

export default SessionsPage;