import React, {useState} from 'react';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../firebase';
import { NavLink, useNavigate } from 'react-router-dom'
import { Button, Input, message } from 'antd';
// import '../App.css'

const Login = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            navigate('/')
            console.log(user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            messageApi.open({
                type: 'error',
                content: 'User not found',
            });
            console.log(errorCode, errorMessage)
        });

    }

    return(
        <>
            {contextHolder}
            <div>
                <h1 className='white'>Upskill Hub</h1>
            </div>
            <div className='island'>
                <div className='title_cnt'>
                <h2>Login</h2>
                <p className='mt40'>
                    <NavLink to="/signup">
                        or create account
                    </NavLink>
                </p>
                </div>
                <form>
                    <div className='control'>
                        <span>Email</span>
                        <Input
                            id="email-address"
                            name="email"
                            type="email"
                            size='large'
                            required
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                    </div>

                    <div className='control'>
                        <span>Password</span>
                        <Input
                            id="password"
                            name="password"
                            type="password"
                            size='large'
                            required
                            onChange={(e)=>setPassword(e.target.value)}
                        />
                    </div>

                    <div className='mt20'>
                        <Button
                            className='wideButton'
                            onClick={onLogin}
                            type={'primary'}
                            size={'large'}
                        >
                            Login
                        </Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Login