import { createSlice } from '@reduxjs/toolkit';
import { fetchUserSessions, apiSaveTrainingSession } from './sessionsAPI';

const initialState = {
    list: []
};

export const sessionsSlice = createSlice({
    name: 'sessions',
    initialState,
    reducers: {
        setSessions: (state, {payload}) => {
            state.list = payload
        }
    }
});

export const { setSessions } = sessionsSlice.actions;

export const selectSessions = (state) => state.sessions.list;

export const fetchSessions = () => async (dispatch, getState) => {
    const state = getState()
    const sessions = await fetchUserSessions(state.user.uid)
    dispatch(setSessions(sessions))
}

export const saveTrainingSession = () => async (dispatch, getState) => {
    const state = getState()

    // get current chat messages and save them for future analysys
    // don't save system messages with prompts or help messages
    const messagesToSave = state.chat.messages.filter(m => m.role === 'user' || m.role === 'assistant')

    if (state.user && state.chat.training && messagesToSave.length > 0) {
        apiSaveTrainingSession(
            state.user.uid,
            state.chat.training.id,
            state.chat.training.data.name,
            new Date().getTime(),
            messagesToSave.map(msg => JSON.stringify(msg)))
    }
}

export default sessionsSlice.reducer;
