import { collection, getDocs, query, where, addDoc, Timestamp, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

export async function fetchUserProfile(email) {
    const arr = [];
    const q = query(collection(db, 'user'), where('email', '==', email))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
        const data = doc.data()
        data.doc_id = doc.id
        if (data.created_time && data.created_time.seconds) {
            data.created_time = data.created_time.seconds * 1000
        }
        else {
            data.created_time = undefined
        }
        if (data.login_time && data.login_time.seconds) {
            data.login_time = data.login_time.seconds * 1000
        }
        else {
            data.login_time = undefined
        }
        arr.push({id: doc.id, data})
    });
    return arr;
}

export async function createUserProfile(userData) {
    const data = {...userData}
    if (userData.created_time) {
        data.created_time = Timestamp.fromMillis(userData.created_time)
    }
    if (userData.login_time) {
        data.login_time = Timestamp.fromMillis(userData.login_time)
    }
    return await addDoc(collection(db, 'user'), data);
}

export async function updateUserProfile(doc_id, userData) {
    const data = {...userData}
    if (userData.created_time) {
        data.created_time = Timestamp.fromMillis(userData.created_time)
    }
    if (userData.login_time) {
        data.login_time = Timestamp.fromMillis(userData.login_time)
    }
    const docRef = doc(db, 'user', doc_id);
    return await updateDoc(docRef, data);
}