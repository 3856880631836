import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const SignupSuccessful = () => {

    const navigate = useNavigate();

    return <Result
        status='success'
        title='Account created.'
        subTitle='Now you can login and found some public trainings.'
        extra={[
            <Button size='large' type='primary' key='console' onClick={() => navigate('/login')}>
                Login
            </Button>,
        ]}
    />
};

export default SignupSuccessful;