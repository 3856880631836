import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSessions } from '../features/saved_sessions/sessionsSlice';
import { Navigator } from '../features/navigator/Navigator';

const ChatHistory = () => {

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const tid = searchParams.get('tid')
    const sessions = useSelector(selectSessions)
    const [messages, setMessages] = useState([])

    useEffect(() => {
        const ssn = sessions && tid ? sessions.find(s => s.data.tid === tid): null;
        if (ssn) {
            setMessages(ssn.data.messages.map(msg => {
                try {
                    return JSON.parse(msg)
                }
                catch(err) {}
                return { content: '' }
            }))
        }
    }, [tid, sessions])

    const onBack = () => {
        navigate(-1)
    }

    return(
        <div className='chat'>
            <Navigator title='History' onBack={onBack}></Navigator>
            <ul>
                {messages && messages.map((m, i) =>
                    <div key={i} className={`line ${m.role === 'user' ? '__right': ''}`}>
                        {m.content &&
                            <li>
                                <span className='message'>{m.content}</span>
                            </li>
                        }
                    </div>
                )}
                <div className='line'>
                    <li></li>
                </div>
            </ul>
        </div>
    )
}

export default ChatHistory;