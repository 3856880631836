import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { collection, getDocs, getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyA7wXcvjnC3ktRzG5jHTK3tbDwEQwyDXFM",
    authDomain: "chattrainer.firebaseapp.com",
    projectId: "chattrainer",
    storageBucket: "chattrainer.appspot.com",
    messagingSenderId: "1092606252678",
    appId: "1:1092606252678:web:01eaa58cccd2ca5e3de307"
}
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const getCollection = async (id) => {
    const arr = [];
    const querySnapshot = await getDocs(collection(db, id));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      arr.push({id: doc.id, data: doc.data()})
    });
    return arr;
}

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export default app;