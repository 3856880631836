import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectMessages,
    closeChat,
    sendMessage,
    selectLastMessage,
    selectUserMessages,
    selectTraining,
    initChat,
    clearMessages,
} from './chatSlice';
import './Chat.css';
import { Modal } from 'antd';
import { Navigator } from '../navigator/Navigator';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { saveTrainingSession } from '../saved_sessions/sessionsSlice';
import { ChatProgress } from './ChatProgress';
import { extractOverallSatisfaction } from '../../app/util';
import { Emoji } from './Emoji';
import { ChatInput } from './ChatInput';
import { selectTrainings } from '../trainings/trainingsSlice';


const DELTA_AUTO_SCROLL = 120

let ulScrollTop = 0

export function Chat() {
    const navigate = useNavigate()
    const messages = useSelector(selectMessages)

    const training = useSelector(selectTraining)
    const allTrainings = useSelector(selectTrainings);
    const userMessages = useSelector(selectUserMessages)
    const lastMessage = useSelector(selectLastMessage)
    const dispatch = useDispatch()

    const messagesEndRef = useRef(null)
    const ulRef = useRef(null)

    const [searchParams] = useSearchParams()
    const tid = searchParams.get('tid')

    const [initialInput, setInitialInput] = useState('');
    const [haveSomethingToSave, setHaveSomethingToSave] = useState(false);
    const [openFinishModal, setOpenFinishModal] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [progress, setProgress] = useState(0)
    const [openExitModal, setOpenExitModal] = useState(false)
    const [feedbackRequested, setFeedbackRequested] = useState(false)
    const [showOnboarding, setShowOnboarding] = useState(false)
    const [inputMenuOpen, setInputMenuOpen] = useState(undefined)

    const onSend = (value) => {
        setInitialInput('')
        dispatch(sendMessage({content: value, role: 'user'}))
        setTimeout(() => { scrollToBottom() }, 500)
    }

    const listenScrollEvent = (event) => {
        ulScrollTop = event.target.scrollTop
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const onBackClick = () => {
        if (haveSomethingToSave) {
            setOpenExitModal(true)
        }
        else {
            goBack()
        }
    }

    const onFinish = () => {
        dispatch(saveTrainingSession())
        goBack()
    }

    const handleCancel = () => {
        setOpenFinishModal(false);
    };

    const handleOkFinish = () => {
        setConfirmLoading(true)
        setTimeout(() => {
            setConfirmLoading(false)
            setOpenFinishModal(false)
            onFinish()
        }, 1500)
    };

    const handleExitCancel = () => {
        setOpenExitModal(false)
    }

    const handleExitOk = () => {
        setConfirmLoading(true)
        setTimeout(() => {
            setConfirmLoading(false)
            setOpenExitModal(false)
            goBack()
        })
    }

    const goBack = () => {
        dispatch(closeChat())
        navigate(-1)
    }

    const onRequestFeedback = () => {
        setFeedbackRequested(true)
        dispatch(sendMessage({content: 'Finish training and give me feedback, please', role: 'user'}))
    }

    const onDetailedFeedbackRequest = () => {
        dispatch(sendMessage({content: 'Give me detailed training feedback', role: 'user'}))
    }

    const onChatInputMenuFinishClick = () => {
        setOpenFinishModal(true)
    }

    useEffect(() => {
        // if user added at least one message he can finish the training
        if (userMessages.length > 0) {
            setHaveSomethingToSave(true)
        }
    }, [userMessages])

    useEffect(() => {
        if (ulRef.current) {
            const delta =  Math.abs(ulRef.current?.scrollHeight - ulScrollTop - ulRef.current?.clientHeight)
            if (delta > 0 && delta < DELTA_AUTO_SCROLL) {
                scrollToBottom()
            }
        }
    }, [lastMessage]);

    useEffect(() => {
        if (training && training.data.show_progress && lastMessage && lastMessage.role === 'assistant') {
            // try to extract assessment mark from last assistant message
            const p = extractOverallSatisfaction(lastMessage.content)
            if (p !== undefined) {
                setProgress(p * 10);
            }
        }
    }, [lastMessage, training])

    useEffect(() => {
        // First time user sees a message about input menu
        // to start training user has to read message and close menu
        if (tid && allTrainings) {
            let onboarded = false
            try {
                onboarded = !!window.localStorage.getItem('chat_onboarded_1')
                if (!onboarded) {
                    setShowOnboarding(true)
                    dispatch(clearMessages())
                    dispatch(sendMessage({content: 'Use menu to finish the training when needed. Then click "Detailed feedback" to see more info about your progress', role: 'help'}))
                    dispatch(sendMessage({content: 'Close menu to continue', role: 'help'}))
                    setTimeout(() => {
                        setInputMenuOpen(true)
                    }, 1000)
                }
            }
            catch(err) {
                console.error('Local storage not supported')
                onboarded = true
            }

            if (onboarded) {
                startTraining()
            }
        }

    }, [tid, allTrainings, showOnboarding])

    const onMenuDropdownClick = () => {
        if (showOnboarding) {
            // small chat onboarding finished, we can start
            try {
                window.localStorage.setItem('chat_onboarded_1', '1')
            }
            catch(err) {}
            setShowOnboarding(false)
            setInputMenuOpen(undefined)
        }
    }

    const startTraining = () => {
        dispatch(initChat(allTrainings.find(t => t.data.id === tid)))
    }

    return (
        <div className='chat_trainings'>
            <Navigator title='Training' onBack={onBackClick}></Navigator>
            {training && training.data.show_progress &&
                <div className="satisfaction_cnt">
                    <ChatProgress percent={progress} />
                    <Emoji percent={progress} />
                </div>
            }
            <ul onScroll={listenScrollEvent.bind(this)} ref={ulRef}>
                {messages
                    .filter(m => showOnboarding ? m.role === 'help': true)
                    .map((m, i) =>
                        <div key={i} className={`line ${m.role === 'user' ? '__right': ''}`}>
                            {m.content &&
                                <li>
                                    <span className='message'>{m.content}</span>
                                </li>
                            }
                        </div>
                    )}
                <div className='line' ref={messagesEndRef}>
                    <li></li>
                </div>
            </ul>
            <ChatInput
                initialValue={initialInput}
                onRequestFeedback={onRequestFeedback}
                onDetailedFeedbackRequest={onDetailedFeedbackRequest}
                hasUserMessages={haveSomethingToSave}
                feedbackRequested={feedbackRequested}
                onFinishClick={onChatInputMenuFinishClick}
                onSend={onSend}
                menuOpen={inputMenuOpen}
                onMenuDropdownClick={onMenuDropdownClick}
            >
            </ChatInput>
            <Modal
                title='Finish training?'
                open={openFinishModal}
                onOk={handleOkFinish}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <p>{'Training results will be saved in My Progress section.'}</p>
            </Modal>
            <Modal
                title='Exit without saving training history?'
                open={openExitModal}
                onOk={handleExitOk}
                confirmLoading={confirmLoading}
                onCancel={handleExitCancel}
            >
                <p>{'You may use "Finish training" button to save training history and exit.'}</p>
            </Modal>
        </div>
    );
}
