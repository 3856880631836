import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchCount, fetchOpenAIResponse } from './chatAPI';
import { AIWhitelistRoles, AppStatus } from '../../app/enums'
import { filterAIMessages, filterHelpMessages } from '../../app/util'
import { wasteTokens } from '../user/userSlice';
import { buildPrompt } from '../../app/prompt';

const NO_START_PROMPT_HELP_MESSAGE = {content: 'This is a chat. No special training here. Free to ask everything.', role: 'help'}

const initialState = {
    value: 0,
    status: AppStatus.Idle,
    inited: false,
    messages: [
        //{content: 'This is a chat. No special training here. Free to ask everything.', role: 'help'},
        //{content: 'Say a joke', role: 'user'}
    ],
    training: undefined,
    userAutoMesssageIndex: -1
}

let openAiAbortController

export const incrementAsync = createAsyncThunk(
  'chat/fetchCount',
  async (amount) => {
    const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
        init: (state) => {
            state.inited = true
            state.messages = []
            state.userAutoMesssageIndex = -1
            state.training = undefined
            state.status = AppStatus.Idle
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload;
        },
        setLastMessageText: (state, {payload}) => {
            const i = state.messages.length-1;
            state.messages[i] = {
                ...state.messages[i],
                content: payload.content
            };
        },
        addMessage: (state, {payload}) => {
            state.messages = [...state.messages, {
                content: payload.content,
                role: payload.role
            }];
        },
        clearMessages: (state) => {
            state.messages = [];
        },
        setTraining: (state, {payload}) => {
            state.training = payload
        },
        setUserAutoMessageIndex: (state, {payload}) => {
            state.userAutoMesssageIndex = payload
        },
        setStatus: (state, {payload}) => {
            state.status = payload || AppStatus.Idle
        },
        close: (state) => {
            state.inited = false
            state.messages = []
            state.userAutoMesssageIndex = -1
            state.training = undefined
            state.status = AppStatus.Idle
        }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = AppStatus.Loading;
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = AppStatus.Idle;
        state.value += action.payload;
      });
  },
});

export const { init, incrementByAmount, setLastMessageText, addMessage, setTraining, setUserAutoMessageIndex, setStatus, clearMessages, close } = chatSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.chat.value)`
export const selectCount = (state) => state.chat.value;
export const selectStatus = (state) => state.chat.status;
export const selectInited = (state) => state.chat.inited;
export const selectTraining = (state) => state.chat.training;
export const selectMessages = (state) => state.chat.messages.filter(m => m.role !== 'system');
export const selectUserMessages = (state) => state.chat.messages.filter(m => m.role === 'user');
export const selectLastMessage = (state) => state.chat.messages.length > 0 ? state.chat.messages[state.chat.messages.length-1]: '';

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};

export const initChat = (training) => (dispatch, getState) => {
    let state = getState()

    if (state.chat.inited !== true) {

        const helpMsgs = filterHelpMessages(state.chat.messages)
        dispatch(init())
        dispatch(incrementByAmount(11))
        dispatch(setTraining(training))

        // keep help messages if they will be before training
        helpMsgs.forEach(m => dispatch(addMessage(m)))

        state = getState()
        if (state.chat.training && state.chat.training.data.start_prompt) {
            let sp = state.chat.training.data.start_prompt
            if (state.chat.training.data.id === 'rextag_lead_qualification') {
                sp = buildPrompt()
            }
            dispatch(addMessage({content: sp, role: 'system'}))
        }

        state = getState()
        if (state.chat.messages.length === 0) {
            dispatch(addMessage(NO_START_PROMPT_HELP_MESSAGE))
        }

        state = getState()
        const msg = filterAIMessages(state.chat.messages)

        // if there is something to init - initial PROMPT
        if (msg.length) {
            // Prepare an empty message for futher async update
            dispatch(addMessage({content: '', role: 'assistant'}))
            dispatch(setStatus(AppStatus.Loading))
            fetchOpenAIResponse(({content, role, abortController, done}) => {
                // async updates from stream
                dispatch(setLastMessageText({content, role}))
                openAiAbortController = abortController
                if (done) {
                    dispatch(setStatus(AppStatus.Idle))
                    dispatch(wasteTokens(calcTokensFromText(content)))
                    dispatch(nextAutoResponse())
                }
            }, msg)
        }
    }
}

const nextAutoResponse = () => (dispatch, getState) => {
    // if this training contains automatic logic
    const state = getState();
    const uMsgs = state.chat.training.data.user_auto_messages
    const nextIndex = state.chat.userAutoMesssageIndex + 1
    if (uMsgs && nextIndex < uMsgs.length) {
        dispatch(setUserAutoMessageIndex(nextIndex))
        dispatch(sendMessage({content: uMsgs[nextIndex], role: 'user'}))
    }
}

export const sendMessage = ({content, role}) => (dispatch, getState) => {
    const prev = getState()
    if (prev.status !== AppStatus.Loading && content && role) {
        dispatch(addMessage({content, role}))
        if (AIWhitelistRoles[role]) {
            const state = getState()
            // Prepare an empty message for futher async update
            dispatch(addMessage({content: '', role: 'assistant'}))
            dispatch(setStatus(AppStatus.Loading))
            fetchOpenAIResponse(({content, role, abortController, done}) => {
                // async updates from stream
                dispatch(setLastMessageText({content, role}))
                openAiAbortController = abortController
                if (done) {
                    dispatch(setStatus(AppStatus.Idle))
                    dispatch(wasteTokens(calcTokensFromText(content)))
                    dispatch(nextAutoResponse())
                }
            }, filterAIMessages(state.chat.messages))
        }
    }
}

export const closeChat = () => (dispatch, getState) => {
    if (openAiAbortController && openAiAbortController.signal.aborted !== true) {
        // avoid two parallel openai requests
        openAiAbortController.abort();
    }
    dispatch(close())
}

export const calcTokensFromText = (text = '') => {
    return Math.round(text.trim().split(/\s+/).length / 3 * 4);
}

export default chatSlice.reducer;
