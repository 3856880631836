import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { Button, Input, Checkbox, message } from 'antd';
import SignupSuccessful from './SignupSuccessful';

const Signup = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [complete, setComplete] = useState(false)
    const [showEmailInput, setShowEmailInput] = useState(false);
    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault()

        if (!agreeToTerms) {
            messageApi.open({
                type: 'error',
                content: 'You must agree to the terms and conditions',
            });
            return;
        }

        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user);
                setComplete(true)
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                messageApi.open({
                    type: 'error',
                    content: 'Cannot sign up',
                });
            });

    }

    return (
        <>
            {contextHolder}
            <div>
                <h1 className='white'>Upskill Hub</h1>
            </div>
            <div className='island'>

                {complete && <SignupSuccessful>
                    </SignupSuccessful>}

                {!complete && <div>
                    <div className='title_cnt'>
                        <h2>Sign up</h2>
                        <p className='mt40'>
                            <NavLink to="/login" >
                                or login
                            </NavLink>
                        </p>
                    </div>
                    <form>
                        <div
                            className={showEmailInput ? 'control' : 'control_no_input'}
                            onClick={() => setShowEmailInput(true)}
                        >
                            <span>Email</span>
                            {showEmailInput &&
                            <Input
                                id="email-address"
                                type="email"
                                size='large'
                                label="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />}
                        </div>

                        <div
                            className={showPasswordInput ? 'control' : 'control_no_input'}
                            onClick={() => setShowPasswordInput(true)}
                        >
                            <span>Password</span>
                            {showPasswordInput &&
                            <Input
                                id="password"
                                type="password"
                                size='large'
                                label="Create password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />}
                        </div>
                        <div className='checkbox_cnt'>
                            <Checkbox onChange={(e) => setAgreeToTerms(e.target.checked)}></Checkbox>
                            <span className='terms_text'>
                                    I agree with the{" "}
                                    <a href="https://upskill-hub.com/legal/terms-of-service" target="_blank" rel="noopener noreferrer">
                                        Terms & Conditions
                                    </a>
                            </span>
                        </div>
                        <div className='mt20'>
                            <Button
                                className='wideButton'
                                onClick={onSubmit}
                                type={'primary'}
                                size={'large'}
                            >
                                Sign up
                            </Button>
                        </div>
                    </form>
                </div>}
            </div>
        </>
    )
}

export default Signup