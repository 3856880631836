import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

export function RecordingButton({ recording, transcribing, onStartRecording, onStopRecording }) {

    const onClick = () => {
        if (recording) {
            onStopRecording()
        }
        else {
            onStartRecording()
        }
    }

    return (
        <div className={`audio_recorder`} onClick={onClick}>
            <span className='audio_status'>
                {(recording && !transcribing) &&
                    <div>
                        <span className='audio_status_layer __1'></span>
                        <span className='audio_status_layer __2'></span>
                    </div>
                }
                {!transcribing &&
                    <span className='audio_status_layer __micro'></span>
                }
                {transcribing && <LoadingOutlined className='transcribing_progress'/>}
            </span>
        </div>
    )
}