import React, { useEffect, useState } from 'react';

const COLORS = {
    0: 'rgb(75, 4, 4)',
    10: 'rgb(146, 11, 11)',
    20: 'rgb(229, 61, 39)',
    30: 'rgb(176, 100, 20)',
    40: 'rgb(195, 130, 21)',
    50: 'rgb(215, 175, 23)',
    60: 'rgb(225, 221, 17)',
    70: 'rgb(166, 225, 17)',
    80: 'rgb(101, 190, 28)',
    90: 'rgb(45, 182, 21)',
    100: 'rgb(45, 182, 21)',
}

export function ChatProgress({percent}) {

    const [changing, setChanging] = useState(false)

    let w = percent
    if (w < 5) {
        w = 5;
    }

    useEffect(() => {
        if (percent > 0) {
            setChanging(true)
            setTimeout(() => {
                setChanging(false)
            }, 2100)
        }
    }, [percent])

    const bc = COLORS[Math.round(w / 10) * 10]

    return (
        <div className={`chat_progress_cnt ${changing ? 'changing': ''}`}>
            <div className="chat_progress" style={{width: `${w}%`, backgroundColor: bc}}></div>
        </div>
    )
}
