import { createSlice } from '@reduxjs/toolkit';
import { AppStatus } from '../../app/enums'
import { fetchAssignments, fetchTrainings } from './trainingsAPI';

const initialState = {
    status: AppStatus.Idle,
    inited: false,
    trainings: [],
    assignments: []
};

export const trainingsSlice = createSlice({
  name: 'trainings',
  initialState,
  reducers: {
        init: (state) => {
            state.inited = true;
        },
        setTrainings: (state, {payload}) => {
            payload.sort((a, b) => (a.data.name && b.data.name) ? a.data.name.localeCompare(b.data.name): 0)
            state.trainings = payload
        },
        setAssignments: (state, {payload}) => {
            state.assignments = payload
        }
  }
});

export const { init, setTrainings, setAssignments } = trainingsSlice.actions;

export const selectTrainings = (state) => state.trainings.trainings;
export const selectAssignments = (state) => state.trainings.assignments;

export const initTrainings = () => async (dispatch, getState) => {
    const state = getState()
    if (!state.trainings.init) {
        dispatch(init())
        const trainings = await fetchTrainings()
        dispatch(setTrainings(trainings))
        const assignmts = await fetchAssignments()
        dispatch(setAssignments(assignmts))
    }
}

export default trainingsSlice.reducer;
