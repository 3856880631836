import { AIWhitelistRoles } from './enums';

export const filterAIMessages = (messages) => {
    return messages.filter(m => !!m.content && !!AIWhitelistRoles[m.role])
}

export const filterHelpMessages = (messages) => {
    return messages.filter(m => !!m.content && m.role === 'help')
}

/**
 * Extract phrase and assessment "Overall satisfaction: 2/10" from the text
 *
 * @param {*} text
 * @returns
 */
export const extractOverallSatisfaction = (text) => {
    const lower = text.toLowerCase()
    const en = /satisfaction: (\d)+(\/10)?/g
    const mEn = en.exec(lower)
    if (mEn && mEn[1]) {
        return parseInt(mEn[1])
    }

    const ru = /удовлетворенность: (\d)+(\/10)?/g
    const mRu = ru.exec(lower)
    if (mRu && mRu[1]) {
        return parseInt(mRu[1])
    }

    return undefined
}