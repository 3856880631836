import { BulbOutlined, ImportOutlined, LineChartOutlined, MenuOutlined, QuestionOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd'
import React from 'react';

export function InputMenu({
        menuOpen,
        finishEnabled,
        onFinishClick,
        feedbackEnabled,
        onRequestFeedback,
        detailedFeedbackEnabled,
        onDetailedFeedbackRequest,
        onMenuDropdownClick,
        onQuizRequest
    }) {

    const items = [
        {
            label: "Finish and get feedback",
            key: "feedback",
            icon: <BulbOutlined />,
            disabled: !feedbackEnabled
        },
        {
            label: "Detailed feedback",
            key: "detailed_feedback",
            icon: <LineChartOutlined />,
            disabled: !detailedFeedbackEnabled
        },
        {
            label: "Quiz (soon)",
            key: "quiz",
            icon: <QuestionOutlined />,
            disabled: true,
        },
        {
            label: "Quit training",
            key: "finish",
            icon: <ImportOutlined />,
            disabled: !finishEnabled
        },
    ];

    const handleMenuClick = (e) => {
        console.log('item click', e);
        if (e.key === 'feedback') {
            onRequestFeedback()
        }
        else if (e.key === 'detailed_feedback') {
            onDetailedFeedbackRequest()
        }
        else if (e.key === 'finish') {
            onFinishClick()
        }
    }

    return (
        <div className='input_menu_wr'>
            <div className='input_menu_cnt'>
                <Dropdown
                    open={menuOpen}
                    trigger='click'
                    placement='topLeft'
                    overlayClassName='input_menu_overlay'
                    menu={{items, onClick: handleMenuClick}}
                    onClick={() => onMenuDropdownClick && onMenuDropdownClick()}>
                <span className='input_menu_btn'>
                    <MenuOutlined />
                </span>
                </Dropdown>
            </div>
        </div>
    )
}
