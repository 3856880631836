import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import "./App.css";
import ChatPage from "./pages/ChatPage";
import { useDispatch, useSelector } from "react-redux";
import { initUser, selectUserStatus } from "./features/user/userSlice";
import { AuthStatus } from './app/enums';
import { ProtectedRoute } from './features/navigator/ProtectedRoute';
import SessionsPage from './pages/SessionsPage';
import ChatHistory from './pages/ChatHistory';

let once = false;

function App() {

    const dispatch = useDispatch();
    const status = useSelector(selectUserStatus);
    const loggedIn = status === AuthStatus.Authorized

    if (!once) {
        once = true;
        dispatch(initUser());
    }

    return (
        <div className="App">
            {status === AuthStatus.Unknown &&
                <div className='page_loader'></div>
            }
            {status !== AuthStatus.Unknown &&
                <Router>
                    <section className="section">
                        <Routes>
                            <Route path="/" element={<ProtectedRoute loggedIn={loggedIn} component={Home}/>} />
                            <Route path="/train" element={<ProtectedRoute loggedIn={loggedIn} component={ChatPage}/>} />

                            <Route path="/sessions" element={<ProtectedRoute loggedIn={loggedIn} component={SessionsPage}/>} />
                            <Route path="/history" element={<ProtectedRoute loggedIn={loggedIn} component={ChatHistory}/>} />
                            <Route path="/signup" element={<Signup />} />
                            <Route path="/login" element={<Login />} />
                        </Routes>
                    </section>
                </Router>
            }
        </div>
    );
}

export default App;
