import { createSlice } from '@reduxjs/toolkit';
import { onAuthStateChanged } from 'firebase/auth';
import { AuthStatus } from '../../app/enums'
import { auth } from '../../firebase';
import { createUserProfile, fetchUserProfile, updateUserProfile } from './userAPI';

const INITIAL_TOKENS = 10000

const initialState = {
    status: AuthStatus.Unknown,
    inited: false,
    uid: undefined,
    tokens: undefined,
    email: undefined,
    created_time: undefined,
    login_time: undefined,
    doc_id: undefined
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
        init: (state) => {
            state.inited = true;
        },
        setAuthorized: (state, {payload}) => {
            state.status = AuthStatus.Authorized
            state.uid = payload.uid
            state.email = payload.email
        },
        setLoggedout: (state, {payload}) => {
            state.status = AuthStatus.Loggedout
            state.uid = undefined
        },
        setUserProfile: (state, {payload}) => {
            if (payload.tokens !== undefined) {
                state.tokens = payload.tokens
            }
            if (payload.created_time !== undefined) {
                state.created_time = payload.created_time
            }
            if (payload.login_time !== undefined) {
                state.login_time = payload.login_time
            }
            if (payload.doc_id !== undefined) {
                state.doc_id = payload.doc_id
            }
        }
  }
});

export const { init, setAuthorized, setLoggedout, setUserProfile } = userSlice.actions;

export const selectUid = (state) => state.user.uid;
export const selectUserStatus = (state) => state.user.status;
export const selectUserTokens = (state) => state.user.tokens;
export const selectUser = (state) => state.user;
export const selectLogo = (state) => {
    const user = state.user
    if (user) {
        const asg = state.trainings.assignments.find(a => a.data.user_email === user.email)
        if (asg) {
            return asg.data.logo
        }
    }
    return undefined
}

export const initUser = () => async (dispatch, getState) => {
    const state = getState()
    if (!state.user.init) {
        dispatch(init())

        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                // ...
                console.log('User logged in successfully. Uid', uid)
                dispatch(setAuthorized({
                    uid: user.uid,
                    email: user.email
                }))

                const profile = await fetchUserProfile(user.email)
                if (profile && profile.length > 0) {
                    console.log('User profile exists', profile[0].data)
                    dispatch(setUserProfile({
                        ...profile[0].data,
                        login_time: new Date().getTime()
                    }))
                    updateUserProfile(profile[0].data.doc_id, {login_time: new Date().getTime()})
                }
                else {
                    // create user profile, when user logins first time
                    const initialUserData = {
                        uid: uid,
                        email: user.email,
                        tokens: INITIAL_TOKENS,
                        created_time: new Date().getTime(),
                        login_time: new Date().getTime()
                    }
                    createUserProfile(initialUserData)
                    dispatch(setUserProfile(initialUserData))
                    console.log('User profile created')
                }
            } else {
                // User is signed out
                console.log('user is logged out')
                dispatch(setLoggedout())
            }
        });
    }
}

export const wasteTokens = (tokensNumber) => async (dispatch, getState) => {
    const state = getState()
    const newTokes = state.user.tokens - tokensNumber
    dispatch(setUserProfile({tokens: newTokes}))
    await updateUserProfile(state.user.doc_id, {tokens: newTokes})
}

export default userSlice.reducer;
