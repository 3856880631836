import React, { useEffect, useState } from 'react';

const EMO = {
    0:  '/assets/emojis/10_Pouting_Face.gif',
    10: '/assets/emojis/10_Pouting_Face.gif',
    20: '/assets/emojis/20_Disappointed_Face.gif',
    30: '/assets/emojis/30_Slightly_Frowning_Face.gif',
    40: '/assets/emojis/40_Unamused_Face.gif',
    50: '/assets/emojis/50_Face_With_Raised_Eyebrow.gif',
    60: '/assets/emojis/60_Thinking_Face.gif',
    70: '/assets/emojis/70_Slightly_Smiling_Face.gif',
    80: '/assets/emojis/80_Smiling_Face_Blushing.gif',
    90: '/assets/emojis/90_Partying_Face.gif',
    100: '/assets/emojis/100_Smiling_Face_With_Hearts.gif',
}

export function Emoji({percent}) {

    const [emojiClass, setEmojiClass] = useState('')
    const [emojiPath, setEmojiPath] = useState('')

    useEffect(() => {
        if (percent > 0) {
            setEmojiPath(EMO[Math.floor(percent / 10) * 10])
            setEmojiClass('visible')
            setTimeout(() => {
                setEmojiClass('hidden')
                setTimeout(() => {
                    setEmojiClass('')
                }, 200)
            }, 3000)
        }
    }, [percent])

    // preload all images since they might be heavy
    useEffect(() => {
        Object.values(EMO).forEach((url) => {
            new Image().src = url
        })
    }, [])

    return (
        <div className="emoji_cnt">
            <img className={`emoji ${emojiClass}`} src={emojiPath} alt="client emoji"></img>
        </div>
    )
}
