import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Sessions.css';
import SessionCard from './SessionCard.js'
import { fetchSessions, selectSessions } from './sessionsSlice';
import { selectTrainings } from '../trainings/trainingsSlice';
import { useNavigate } from 'react-router-dom';

let once = false

export default function SessionsList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [imageMap, setImageMap] = useState({})

    const sessions = useSelector(selectSessions);
    const allTrainings = useSelector(selectTrainings);

    useEffect(() => {
        if (!once) {
            once = true
            dispatch(fetchSessions())
        }
    }, [dispatch])

    useEffect(() => {
        const map = {}
        sessions.forEach(s => {
            map[s.data.tid] = 'none'
        })
        allTrainings.forEach(t => {
            if (map[t.id]) {
                map[t.id] = t.data.image
            }
        })
        setImageMap(map)
    }, [sessions, allTrainings])

    const onOpen = (tid) => {
        navigate(`/history?tid=${tid}`)
    }

    return (
        <div className='train_card_list'>
            {sessions && sessions.map((t) =>
                <SessionCard
                    key={t.id}
                    image={imageMap[t.data.tid]}
                    trainingId={t.data.tid}
                    name={t.data.tname}
                    timestamp={t.data.time}
                    onStart={onOpen}>
                </SessionCard>
            )}
            {sessions && sessions.length === 0 &&
                <p>You didn't finished any trainings</p>
            }
        </div>
    )
}
