export const OPEN_AI_MODEL = 'gpt-4'

export const OPEN_AI_KEY = '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'

// export const API_URL = 'http://127.0.0.1:5000'
export const API_URL = 'https://octopus-app-rrkte.ondigitalocean.app'

export const COMPLETION_API = 'https://api.openai.com/v1/chat/completions'
// export const COMPLETION_API = `${API_URL}/completion`

export const RELEVANT_ARR_SLICE = 3;