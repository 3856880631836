import { ArrowLeftOutlined } from '@ant-design/icons';
import React from 'react';
import './Navigator.css';

export function Navigator({title, onBack, action}) {

    return (
        <div className='navigation'>
            <div className='navigation_title'>
                <span className='navig_back' onClick={onBack}>
                    <ArrowLeftOutlined />
                </span>
                <h2>{title}</h2>
            </div>
            {action &&
                <span className='navigator_actions'>
                    {action}
                </span>
            }
        </div>
    )
}
