import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import './Sessions.css';

export default function SessionCard({dataId, trainingId, name, timestamp, image, onStart}) {

    const s = {
        backgroundImage: image ? `url(${image})`: 'none'
    }

    const onStartClick = () => {
        onStart && onStart(trainingId)
    }

    const d = new Date(timestamp)
    const timeFormatted = `${d.getDate()} ${d.toLocaleString('default', { month: 'long' }).substring(0, 3)}`

    return (
        <div className='train_card' onClick={onStartClick}>
            <div className='train_image' style={s}></div>
            <div className='train_text'>
                <p className='train_name'>{name}</p>
                <p className='train_length'>{timeFormatted}</p>
                <Button className='train_btn' size='large'>
                    View dialog
                    <ArrowRightOutlined/>
                </Button>
            </div>
        </div>
    )
}
