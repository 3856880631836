import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Trainings.css';
import { useNavigate } from 'react-router-dom';
import Traincard from './Traincard.js'
import { initTrainings, selectAssignments, selectTrainings } from './trainingsSlice';
import { selectUser, selectUserTokens } from '../user/userSlice';
import { Button, Result } from 'antd';

let once = false

export default function TrainingList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const allTrainings = useSelector(selectTrainings);
    const assignmts = useSelector(selectAssignments);
    const user = useSelector(selectUser)
    const tokens = useSelector(selectUserTokens)

    const [activeTrainings, setActiveTrainings] = useState([])
    const [paywall, setPaywall] = useState(false)

    const onTrainStart = (idTraining) => {
        if (tokens > 0) {
            navigate(`/train?tid=${idTraining}`)
        }
        else {
            setPaywall(true)
        }
    }

    const filterTrainings = (allTrainings, assignmts, user) => {
        if (user && user.email && allTrainings && allTrainings.length > 0 && assignmts && assignmts.length > 0) {
            const asg = assignmts.find((a) => a.data.user_email === user.email)
            return allTrainings.filter(t => t.data.public || !!(asg && asg.data.training_ids && asg.data.training_ids.includes(t.data.id)) )
        }
        return []
    }

    useEffect(() => {
        if (!once) {
            once = true
            dispatch(initTrainings())
        }
    }, [dispatch])

    useEffect(() => {
        setActiveTrainings(filterTrainings(allTrainings, assignmts, user))
    }, [allTrainings, assignmts, user])

    return (
        <div className='train_card_list'>
            {/* 'Just talk!' description='You can talk with Open AI without any prompts. Good for tests.' length='3 min' onStart={onTrainStart}></Traincard> */}
            {activeTrainings && activeTrainings.map((t) =>
                <Traincard
                    key={t.id}
                    trainingId={t.data.id}
                    image={t.data.image}
                    name={t.data.name}
                    description={t.data.description}
                    length={t.data.length}
                    onStart={onTrainStart}>

                </Traincard>
            )}
            {paywall &&
                <div className='island fixed'>
                    <Result
                        status='warning'
                        title='No tokens'
                        subTitle='Contact us to continue training. Use "Book a Demo" form on www.upskill-hub.com'
                        extra={[
                            <Button size='large' type='primary' key='close' onClick={() => setPaywall(false)}>Close</Button>,
                        ]}
                    />
                </div>
            }
        </div>
    );
}
