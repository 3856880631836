import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TrainingList from '../features/trainings/TrainingList.js'
import { MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { selectLogo } from '../features/user/userSlice';
import { Menu } from '../features/menu/Menu';
import { closeChat } from '../features/chat/chatSlice.js';

const Home = () => {

    const dispatch = useDispatch()
    const logo = useSelector(selectLogo);
    const [menuOpen, setMenuOpen] = useState(false);

    const showMenu = () => {
        setMenuOpen(true);
    };

    const onCloseMenu = () => {
        setMenuOpen(false);
    };

    useEffect(() => {
        dispatch(closeChat())
    }, [dispatch])

    return(
        <>
            <nav>
                <div className='top_actions'>
                    <Button className='btn menu_btn' onClick={showMenu}>
                        <MenuOutlined />
                    </Button>
        		</div>
                <div className='top'>
                    {logo &&
                        <div className='logo' style={{backgroundImage: `url(${logo})`}}></div>
                    }
                    <h2>
                        Select training
                    </h2>
                </div>
                <TrainingList></TrainingList>
            </nav>
            <Menu menuOpen={menuOpen} onCloseMenu={onCloseMenu}></Menu>
        </>
    )
}

export default Home;