import { Drawer } from 'antd';
import { signOut } from 'firebase/auth';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { selectUserTokens } from '../user/userSlice';
import './Menu.css';

export function Menu({menuOpen, onCloseMenu}) {

    const navigate = useNavigate()
    const tokens = useSelector(selectUserTokens)

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate('/login');
            console.log('Signed out successfully')
        }).catch((error) => {
            // An error happened.
        });
    }

    return (
        <Drawer
            title="Upskill"
            placement={'left'}
            closable={false}
            onClose={onCloseMenu}
            open={menuOpen}
            key={'menu'}
            className='menu_panel'>
                <p className='menu_action' onClick={() => navigate('/')}>Trainings</p>
                <p className='menu_action' onClick={() => navigate('/sessions')}>My progress</p>
                <p className='menu_action disabled'>Tokens balance: {tokens}</p>
                <p className='menu_action' onClick={handleLogout}>Log out</p>
        </Drawer>
    )
}
