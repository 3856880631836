import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import chatReducer from '../features/chat/chatSlice';
import sessionsReducer from '../features/saved_sessions/sessionsSlice';
import trainingsReducer from '../features/trainings/trainingsSlice';
import userReducer from '../features/user/userSlice';

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        chat: chatReducer,
        sessions: sessionsReducer,
        trainings: trainingsReducer,
        user: userReducer
    },
});
