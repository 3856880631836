import React, { useEffect, useState } from 'react';
import { RecordingButton } from './RecordingButton';
import TextArea from 'antd/es/input/TextArea';
import { AppStatus } from '../../app/enums';
import { useSelector } from 'react-redux';
import { selectStatus, selectTraining } from './chatSlice';
import { useWhisper } from '@chengsokdara/use-whisper'
import { OPEN_AI_KEY } from '../../app/config';
import './ChatInput.css';
import { InputMenu } from './InputMenu';

const MAX_TIME_VOICE = 29
let pauseTimerInterval = null

export function ChatInput({ initialValue, menuOpen, onSend, hasUserMessages, feedbackRequested, onRequestFeedback, onDetailedFeedbackRequest, onFinishClick, onMenuDropdownClick }) {

    const status = useSelector(selectStatus)
    const training = useSelector(selectTraining)

    const [input, setInput] = useState('');
    const [pauseTimer, setPauseTimer] = useState(0)

    let {
        recording,
        transcript,
        transcribing,
        startRecording,
        stopRecording,
      } = useWhisper({
        apiKey: OPEN_AI_KEY,
        removeSilence: true,
        // nonStop: true,
        // stopTimeout: 1000, // auto stop after 1 seconds of silence,
        whisperConfig: {
            language: training?.data?.recorder_lang || 'en'
        },
    })

    useEffect(() => {
        if (transcript && transcript.text) {
            setInput(input + ' ' + transcript.text)
        }
    }, [transcript, transcript.text])

    useEffect(() => {
        setInput(initialValue)
    }, [initialValue])

    useEffect(() => {
        if (pauseTimerInterval) {
            clearInterval(pauseTimerInterval)
            pauseTimerInterval = null
        }
        if (recording) {
            setPauseTimer(0)
            pauseTimerInterval = setInterval(() => {
                setPauseTimer((prevTimer) => {
                    if (prevTimer >= MAX_TIME_VOICE) {
                        onStopRecording()
                        clearInterval(pauseTimerInterval)
                        pauseTimerInterval = null
                        return prevTimer
                    }
                    return prevTimer + 1
                })
            }, 1000)
        }
    }, [recording])

    const onChange = (event) => {
        setInput(event.target.value)
    }

    const onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            onSendClick();
        }
    }

    const onStartRecording = () => {
        startRecording()
    }

    const onStopRecording = () => {
        stopRecording()
    }

    const onSendClick = () => {
        if (status !== AppStatus.Loading) {
            onSend(input)
            setInput('')
        }
    }

    const formatTimer = (seconds) => {
        const secstr = seconds < 10 ? '0'+seconds: seconds.toString()
        return `0:${secstr}`
    }

    return (<div className='send_block'>
        <InputMenu
            menuOpen={menuOpen}
            finishEnabled={hasUserMessages}
            onFinishClick={onFinishClick}
            feedbackEnabled={hasUserMessages}
            detailedFeedbackEnabled={feedbackRequested}
            onRequestFeedback={onRequestFeedback}
            onDetailedFeedbackRequest={onDetailedFeedbackRequest}
            onMenuDropdownClick={onMenuDropdownClick}
        >
        </InputMenu>
        <div className='send_input_cnt'>
            {recording &&
                <div className='pause_cnt'>
                    <span className='pause_timer'>{formatTimer(pauseTimer)}</span>
                    <span className='pause_btn' onClick={onStopRecording}></span>
                </div>
            }
            {!recording &&
                <div className='text_back'>
                    <TextArea maxLength={1000} autoSize={true} className='send_input' placeholder='Message' onChange={onChange} value={input} onKeyDown={onEnterPress}/>
                </div>
            }
        </div>
        <div className='send_button_wr'>
            {input &&
                <div className='send_button_cnt'>
                    <span className={`send_button ${status === AppStatus.Loading ? 'disabled': ''}`} onClick={onSendClick}></span>
                </div>
            }
            {!input &&
                <RecordingButton transcribing={transcribing} recording={recording} onStartRecording={onStartRecording} onStopRecording={onStopRecording}></RecordingButton>
            }
        </div>
    </div>)
}
